






















































































































import {
  computed,
  defineComponent,
  defineVue3AsyncComponent
} from '~/utils/nuxt3-migration'
import CAvatar from '~/components/shared/configurable/image/avatar/CAvatar.vue'
import { ciUserCircle as ciUserCircleRegular } from '~/icons/source/regular/user-circle'
import { ciUserCircle as ciUserCircleSolid } from '~/icons/source/solid/user-circle'
import { ciFolderOpen } from '~/icons/source/regular/folder-open'
import { ciParking } from '~/icons/source/regular/parking'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { useDep } from '~/compositions/dependency-container'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { UserType } from '~/models/user/types'
import { ciBookmark } from '~/icons/source/light/bookmark'

export default defineComponent({
  components: {
    CAvatar,
    HeaderCredits: defineVue3AsyncComponent(() =>
      import('~/components/car/header/HeaderCredits.vue')
    ),
    HeaderUserMenuAdminList: defineVue3AsyncComponent(() =>
      import('~/components/car/header/HeaderUserMenuAdminList.vue')
    ),
    HeaderUserMenuList: defineVue3AsyncComponent(() =>
      import('~/components/car/header/HeaderUserMenuList.vue')
    )
  },
  props: {
    inModal: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const legacyUrlService = useDep(LegacyUrlService)
    const { state: userState, getters: userGetters } = useNamespacedStore<
      UserState
    >(USER_NS)

    const userLogoutLink = legacyUrlService.getUserLogoutUrl()

    const username = computed(() => userState.name)

    const avatar = computed(() => userState.avatar)

    const isAdmin = computed(() => userGetters('isAdmin'))

    const isAnon = computed(() => userGetters('isSingleOrAnon'))

    const showCredits = computed(
      () => !isAdmin.value && userState.type !== UserType.AUDIT_PROVIDER
    )

    return {
      ciUserCircleRegular,
      ciUserCircleSolid,
      ciFolderOpen,
      ciParking,
      ciBookmark,
      username,
      avatar,
      isAdmin,
      isAnon,
      userLogoutLink,
      showCredits
    }
  }
})
